<template>
  <div id="app">
    <index></index>
  </div>
</template>

<script>
import index from './views/index'
export default {
  name: 'App',
  components: {
    index
  }
}
</script>

<style>

</style>
